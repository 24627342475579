import React, { useState } from "react"
import PropTypes from "prop-types"
import SEO from "../seo"
import Link from "gatsby-link"
import styles from "./index.module.less"

const Layout = ({
  children,
  meta = { title: "", description: "", image: "" },
}) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <div className={styles.body_container}>
      <SEO
        title={meta.title}
        description={meta.description}
        image={meta.image}
      />
      <header className={styles.header}>
        <div className="container d-flex align-items-center">
          <div className={styles.header_logo}>
            <Logo />
          </div>
          <div className={styles.main_menu_wrapper}>
            <ul
              className={`${styles.main_menu} d-none ${
                mobileMenu ? "" : "d-md-block"
              }`}
            >
              <MenuLinks />
            </ul>
            <button
              className={`btn btn-default ${mobileMenu ? "" : "d-md-none"} ${
                styles.mobile_menu_icon
              }`}
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              {mobileMenu ? (
                <img
                  src={require("./mobile-menu-close.svg")}
                  alt="Close menu"
                />
              ) : (
                <img src={require("./mobile-menu-icon.svg")} alt="Open menu" />
              )}
            </button>
          </div>
        </div>
      </header>
      {mobileMenu ? (
        <MobileMenu closeMenu={() => setMobileMenu(false)} />
      ) : (
        <>
          <main>{children}</main>
          <footer className={styles.footer}>
            <div className="container">
              <hr className={"my-0"} />
              <div className={styles.footer_logo}>
                <Logo />
              </div>
              <ul className={styles.footer_menu}>
                <MenuLinks />
              </ul>
              <p className={styles.footer_text}>
                Made with <img src={require("./heart.svg")} alt="love" /> by{" "}
                <a href="https://enso.digital">Enso</a> &{" "}
                <a href="https://worksimply.com">Worksimply</a>
              </p>
            </div>
          </footer>
        </>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const MobileMenu = ({ closeMenu = () => null }) => {
  return (
    <div className="container-fluid">
      <ul className={styles.mobile_menu}>
        <MenuLinks closeMenu={closeMenu} />
      </ul>
    </div>
  )
}

const MenuLinks = ({ closeMenu = () => null }) => {
  return (
    <>
      <li>
        <Link to={"/about"} onClick={closeMenu}>
          About
        </Link>
      </li>
      <li>
        <Link to={"/episodes"} onClick={closeMenu}>
          Episodes
        </Link>
      </li>
      <li>
        <Link to={"/contact"} onClick={closeMenu}>
          Contact
        </Link>
      </li>
      <li>
        <Link to={"/donate"} onClick={closeMenu}>
          Donate
        </Link>
      </li>
    </>
  )
}

const Logo = () => {
  return (
    <Link to={"/"}>
      <img src={require("./logo.svg")} alt="" />
    </Link>
  )
}
